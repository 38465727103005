@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap');

:root {
  --primaryColor: #661514;
  --secondaryColor: #fff;
  --titleColor: #11100E;
  --textColor: #2D2D2D;
  --bgColor: #6615141A;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
  color: inherit !important;
  font-size: inherit;
  font-family: 'Roboto', sans-serif;
}

img {
  width: 100%;
}

ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}

/* Active Nav Link */

.active {
  position: relative;
  display: block;
  &:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: var(--whiteColor);
    bottom: -0.3rem;
    left: 0;
    transform: scale(1) !important;
  }
}
