@import "../../../styleFunctions/mixins";

.productCard {
    @include flexContainer;
    flex-direction: column;
    > div, p, button {
        flex: 1;
        margin: 0;
        width: 100%;
    }
    img {
        border-radius: 1rem;
        height: 200px;
        object-fit: cover;
    }
    p {
        font-size: 20px;
    font-weight: 600;
    text-align: center;
    }
    button {
        font-size: 1.4rem;
        padding-block: 1.2rem;
    }
}