@import "../../styleFunctions/mixins";

.homePage {
  .bannerSection {
    position: relative;
    min-height: 70rem;
    @include flexContainer;
    .bannerContent {
      width: 100%;
      position: relative;
      z-index: 2;
      padding-inline: 3rem;
      h2 {
        margin-bottom: 0;
        color: var(--secondaryColor);
      }
      p {
        margin: 3rem 0;
        font-size: 2rem;
        font-weight: 400;
        color: var(--secondaryColor);
      }
      button {
        background-color: var(--secondaryColor);
        border-color: var(--secondaryColor);
        color: var(--primaryColor);
      }
    }
  }
  .aboutSection {
    > div {
      padding-bottom: 15rem;
      > div {
        padding: 2rem;
        position: relative;
        > .aboutBgImage {
          @include backgroundImage($image: url(../../assets/homeAbout.jpg));
          overflow: hidden;
          position: absolute;
          width: calc(100% - 100px);
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 2rem;
          z-index: 1;
        }
        > img {
          border-radius: 2rem;
          position: absolute;
          z-index: 2;
          width: 100%;
          max-width: 480px;
          height: 460px;
          bottom: -50px;
          right: 20px;
        }
        p {
          margin: 3rem 0 0 0;
        }
        .coreValuesBox {
          margin-top: 5rem;
          border-left: 4px solid var(--primaryColor);
          padding-left: 3rem;
          h2 {
            font-size: 3.6rem;
          }
        }
        a {
          margin-top: 3rem;
        }
      }
    }
  }
  .qaSection {
    background-color: var(--bgColor);
    > div {
      > div {
        padding-bottom: 10rem;
        > div {
          padding: 2rem;
        }
        .qaImage {
          @include backgroundImage($image: url(../../assets/qaImg.png));
        }
        .qaList {
          margin-top: 5rem !important;
          @include flexContainer;
          flex-direction: column;
          li {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 15px;
            padding: 2rem;
            border-radius: 2rem;
            background-color: var(--secondaryColor);
            svg {
              background-color: var(--primaryColor);
              fill: var(--secondaryColor);
              border-radius: 50%;
              width: 3rem;
              height: 3rem;
              padding: 0.6rem;
              margin-top: 0.2rem;
            }
            p {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .productSection {
    padding: 10rem 3rem 10rem;
    > div {
      &:nth-of-type(1) {
        margin-bottom: 5rem;
        hr {
          margin: 1rem auto;
        }
      }
    }
    h2 {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .homePage {
    .aboutSection {
      > div {
        padding-bottom: 10rem;
        flex-direction: column-reverse;
        > div {
          padding: 0;
          > .aboutBgImage {
            display: none;
          }
          > img {
            height: 260px;
            position: unset;
            margin-top: 5rem;
          }
        }
      }
    }
    .qaSection {
      > div {
        > div {
          > div {
            padding: 0;
          }
          .qaImage {
            min-height: 50rem;
            margin-top: 3rem;
          }
        }
      }
    }
    .productSection {
      .productList  {
        > div {
          margin-bottom: 4rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
