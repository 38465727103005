.loader {
    --dim: 3rem;
    width: var(--dim);
    height: var(--dim);
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    animation: spin_51 1.5s infinite linear;
  }
  
  @keyframes spin_51 {
    from {
      transform: rotate(0)
    }
  
    to {
      transform: rotate(360deg)
    }
  }