.productPage {
  .productList {
    > div {
      padding-bottom: 10rem;
      > div {
        padding: 2rem;
      }
    }
  }
}
