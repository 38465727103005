@import "../../../styleFunctions/mixins";

.formBlock {
  max-width: 85%;
  margin: 0 auto;
  background-color: transparent;
  form {
    @include flexContainer($gap: 3rem);
    flex-direction: column;
    .fieldContainer {
      width: 100%;
      @include flexContainer;
      &:nth-last-of-type(1) {
        justify-content: flex-start;
        margin-top: 3rem;
      }
      > div {
        width: 100%;
        @include flexContainer($gap: 5px);
        flex-direction: column;
        label {
          width: 100%;
          letter-spacing: 2px;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
        }
        input {
          min-height: 50px;
        }
      }
      input,
      textarea {
        width: 100%;
        flex: 1 0 48%;
        padding: 1rem 1.5rem;
        border: 0.2rem solid var(--secondaryColor);
        font-size: 1.7rem;
        transition: all 0.3s ease-in-out;
        background-color: #6615141a;
        &:focus {
          outline: none;
        }
      }
      input {
        border-radius: 50px;
      }
      textarea {
        border-radius: 20px;
      }
      button {
        svg {
          fill: inherit;
        }
        &:hover {
          color: var(--primaryColor);
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .formBlock {
    max-width: 100%;
    form {
      .fieldContainer {
        flex-direction: column;
      }  
    }
  }
}
