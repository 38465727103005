@import "../../styleFunctions/mixins";

.supplierPage {
  .aboutSecondSection {
    > div {
      padding-bottom: 10rem;
      > div {
        &:nth-of-type(1) {
          padding-right: 8rem;
          padding-block: 2rem;
          @include backgroundImage($image: url(../../assets/apfGrp.png));
          position: relative;
          background-size: contain;
        }
      }
    }
    h2 {
      font-weight: 600;
      font-size: 4.2rem;
      line-height: 5.2rem;
      z-index: 2;
      position: relative;
    }
    p {
      // font-size: 2rem;
    }
  }
  .supplierFormSection {
    @include backgroundImage($image: url(../../assets/aboutBg.jpg));
    @include flexContainer;
    background-size: 100%;
    background-position: right;
    // min-height: 55rem;
    position: relative;
    &::after {
      content: "";
      width: 42.5%;
      height: 100%;
      background-color: var(--primaryColor);
      position: absolute;
      top: 0;
      left: 0;
    }
    > div {
      width: 100%;
      z-index: 1;
      > div {
        padding-bottom: 0;
        padding-top: 0;
        align-items: center;
        > div {
          &:nth-of-type(2) {
            padding-block: 7rem;
          }
        }
      }
      h2 {
        color: var(--secondaryColor);
        // transform: rotate(-90deg) translate(-25%, -50%);
        text-align: center;
        margin: 0;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 44px;
        color: var(--secondaryColor);
        text-align: center;
        max-width: 60%;
        margin: 20px auto 0;
      }
      blockquote {
        position: relative;
        margin-left: 4rem;
        &::before {
          content: open-quote;
          position: absolute;
          top: -21px;
          left: -12px;
          display: block;
          color: var(--primaryColor);
          line-height: 100%;
          font-size: 11.5rem;
          font-family: Georgia;
        }
        p {
          color: #000000;
          font-family: "Roboto", Sans-serif;
          font-size: 20px;
          text-indent: 2em;
          font-style: italic;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .supplierPage {
    .aboutSecondSection {
      > div {
        > div {
          &:nth-of-type(1) {
            padding: 0;
            padding-bottom: 3rem;
            h2 {
              text-align: center;
              font-size: 3.6rem;
            }
          }
          p {
            text-align: center;
          }
        }
      }
    }
    .supplierFormSection {
      background-size: cover;
      background-position: center;
      &::after {
        width: 100%;
        height: 43rem;
      }
      > div {
        padding-block: 7rem;
        h2 {
          // color: var(--titleColor);
          transform: unset;
          margin-bottom: 3rem;
        }
        p {
          // color: var(--textColor);
          max-width: 100%;
        }
        blockquote {
          margin-left: 0;
        }
      }
    }
  }
}
