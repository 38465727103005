.commonProductPage {
  .commonPageInner {
    > div {
      padding-bottom: 10rem;
      > div {
        padding: 2rem;
        .productTitle {
          font-size: 32px;
          font-weight: 500;
        }
        .productDescription {
          margin-top: 3rem;
          p {
            font-size: 1.7rem;
          }
          ul {
            margin-top: 3rem !important;
            list-style: disc !important;
            padding-left: 20px !important;
            li {
              font-size: 1.7rem;
              margin-bottom: 1.5rem;
            }
          }
        }
        .productMainSlider {
          img {
            height: 400px;
            object-fit: cover;
            object-position: center;
          }
        }
        .productGallerySlider {
          margin-top: 2rem;
          img {
            // height: 120px;
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            object-position: center;
          }
          > div {
            > div {
              > div {
                display: flex !important;
                align-items: center;
                gap: 20px;
              }
            }
          }
        }
        .featureList {
          margin-top: 4rem !important;
          border: 1px solid #000;
        }
      }
    }
  }
}
