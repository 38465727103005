.menuSliderContainer {
  position: absolute;
  width: 100% !important;
  height: 100%;
  background-color: var(--secondaryColor);
  height: calc(100vh - 70px);
  top: 10rem;
  right: 0;
  z-index: 9999999;
  box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  border-left: 0.2rem solid var(--primaryColor);
  ul {
    li {
      font-size: 1.8rem;
      color: var(--textColor);
      font-weight: 500;
      a {
        display: block;
        padding: 4rem 2rem;
        border-bottom: 0.2rem solid var(--primaryColorColor);
        &:after {
          display: none !important;
        }
      }
      button {
        max-width: 70%;
      }
    }
  }
}
