@import "../../styleFunctions/mixins";

.aboutPage {
  .aboutSecondSection {
    > div {
      padding-bottom: 10rem;
      > div {
        &:nth-of-type(1) {
          padding-right: 8rem;
          padding-block: 2rem;
          @include backgroundImage($image: url(../../assets/apfGrp.png));
          position: relative;
          background-size: contain;
        }
      }
    }
    h2 {
      font-weight: 600;
      font-size: 4.2rem;
      line-height: 5.2rem;
      z-index: 2;
      position: relative;
    }
    p {
      // font-size: 2rem;
    }
  }
  .directorNoteSection {
    @include backgroundImage($image: url(../../assets/aboutBg.jpg));
    @include flexContainer;
    background-size: 65%;
    background-position: right;
    min-height: 55rem;
    position: relative;
    &::after {
      content: "";
      width: 35%;
      height: 100%;
      background-color: var(--primaryColor);
      position: absolute;
      top: 0;
      left: 0;
    }
    > div {
      width: 100%;
      z-index: 1;
      > div {
        padding-bottom: 0;
        padding-top: 0;
      }
      h2 {
        color: var(--secondaryColor);
        transform: rotate(-90deg) translate(-25%, -50%);
        text-align: center;
        margin: 0;
      }
      blockquote {
        position: relative;
        margin-left: 4rem;
        &::before {
          content: open-quote;
          position: absolute;
          top: -21px;
          left: -12px;
          display: block;
          color: var(--primaryColor);
          line-height: 100%;
          font-size: 11.5rem;
          font-family: Georgia;
        }
        p {
          color: #000000;
          font-family: "Roboto", Sans-serif;
          font-size: 20px;
          text-indent: 2em;
          font-style: italic;
        }
      }
    }
  }
  .valuesContainer {
    > div {
      padding-bottom: 10rem;
      .valuesContent {
        @include flexContainer($gap: 5remb);
        flex-direction: column;
        > div {
          width: 100%;
        }
        .valueBox {
          @include flexContainer($alignItems: flex-start);
          flex-wrap: wrap;
          > div {
            &:nth-of-type(1) {
              flex-grow: 1;
              flex-basis: 6rem;
            }
            &:nth-of-type(2) {
              flex-grow: 1;
              flex-basis: 50rem;
              @include flexContainer;
              flex-direction: column;
              h2,
              p {
                width: 100%;
              }
              h2 {
                font-size: 25px;
                font-weight: 600;
                color: var(--primaryColor);
              }
            }
          }
          .valueImg {
            img {
              max-width: 90%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .aboutPage {
    .aboutSecondSection {
      > div {
        > div {
          &:nth-of-type(1) {
            padding: 0;
            padding-bottom: 3rem;
            h2 {
              text-align: center;
              font-size: 3.6rem;
            }
          }
          p {
            text-align: center;
          }
        }
      }
    }
    .directorNoteSection {
      background-size: cover;
      background-position: center;
      &::after {
        display: none;
      }
      > div {
        padding-block: 7rem;
        h2 {
          color: var(--titleColor);
          transform: unset;
          margin-bottom: 3rem;
        }
        blockquote {
          margin-left: 0;
        }
      }
    }
    .valuesContainer {
      .valuesContent {
        padding-top: 4rem;
      }
      .valueBox {
        margin-bottom: 4rem;
        &:last-child {
          margin-bottom: 0;
        }
        .valueImg {
          text-align: center;
          img {
            max-width: 12rem !important;
          }
        }
        .content {
          h2 , p {
            text-align: center;
          }
        }
      }
    }
  }
}
