@import "../../../styleFunctions/mixins";

header {
  .bottomHeader {
    @include flexContainer($justifyContent: flex-start, $gap: 0);
    background-color: var(--secondaryColor);
    height: 12.4rem;
    .logoContainer {
      a {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          max-width: 18rem;
          // border-radius: 50%;
        }
        h2 {
          font-size: 2.6rem;
          line-height: 3.8rem;
          margin: 0;
        }
      }
    }
    > div {
      > div {
        align-items: center;
        .mainMenu {
          @include flexContainer($gap: 5rem, $justifyContent: flex-end);
          li {
            font-size: 1.8rem;
            font-weight: 500;
            color: var(--primaryColor);
            a {
              color: inherit;
              text-transform: capitalize;
              position: relative;
              &:after {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                background-color: var(--whiteColor);
                bottom: -0.4rem;
                left: 0;
                transform: scale(0);
                transition: all 0.2s ease-in-out;
              }
              &:hover {
                &:after {
                  transform: scale(1);
                  transform-origin: center;
                }
              }
            }
          }
        }
        .headerColThree {
          @include flexContainer($justifyContent: flex-end, $gap: 2rem);
          position: relative;
          ul {
            @include flexContainer($justifyContent: flex-end, $gap: 2rem);
            li {
              color: var(--whiteColor);
              font-size: 1.4rem;
              font-weight: 500;
              @include flexContainer($justifyContent: flex-end, $gap: 0.8rem);
            }
          }
        }
      }
    }
  }
  .mobileHeader {
    display: none;
    transition: all 0.5s ease-in-out;
  }
}

@media only screen and (max-width: 1280px) {
  header {
    // .topHeader {
    //   display: none;
    // }
    .bottomHeader {
      display: none;
    }
    .mobileHeader {
      @include flexContainer($justifyContent: space-between);
      background-color: var(--secondaryColor);
      position: relative;
      height: 10rem;
      padding-inline: 4rem;
      > div {
        &:nth-of-type(3) {
          label {
            margin: 0 0 0 auto;
          }
        }
      }
      h1 {
        color: #fff;
        font-size: 5rem;
        text-align: center;
      }
      .mobileLogo {
        img {
          max-width: 12rem;
          // border-radius: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  header {
    .mobileHeader {
      > div {
        width: 100%;
        &:nth-of-type(1) {
          display: none;
        }
      }
    }
  }
}
